import React, { ReactNode, useEffect, useRef, useMemo } from 'react'
import s from './index.module.scss'
import cn from 'classnames'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css'
import Image from 'next/image'
import { useRouter } from 'next/router'

export enum CardType {
  Type1 = 1,
  Type2 = 2,
  Type3 = 3,
  Type4 = 4,
  Type5 = 5,
  Custom = 'custom'
}

type Card = {
  id?: number | string
  title?: string | ReactNode
  content?: string | ReactNode
  icon?: string
  wrapperClass?: string
  typeBg?: string
  aos?: boolean
}
type Props = {
  wrapperClass?: string
  cardClass?: string
  cardItemClass?: string
  list: Card[]
  cardType: 1 | 2 | 3 | 4 | 5 | 'custom'
  renderChild?: (item: any, index: number) => ReactNode
  typeBg?: string
  aos?: boolean
  swiperIndex?: number
  onSlideChange?: (index: number) => void

}

const GenericCard: React.FC<Card & { cardType: CardType; typeBg?: string }> = React.memo(function GenericCard(props) {
  // eslint-disable-next-line react/prop-types
  const { title, content, icon, wrapperClass, aos, cardType, typeBg } = props
  const dataAos = aos ? 'fade-up' : ''

  const cardClass = useMemo(() => {
    switch (cardType) {
      case CardType.Type1: return s.card_type_1
      case CardType.Type2: return s.card_type_2
      case CardType.Type3: return s.card_type_3
      case CardType.Type4: return s.card_type_4
      case CardType.Type5: return s.card_type_5
      default: return ''
    }
  }, [cardType])

  return (
    <div className={cn(s.card_swiper_item, cardClass, wrapperClass)}>
      {title && (
        <div className={cn(s.card_swiper_title, 'title3', { [s.card_swiper_title_Blue]: typeBg === 'other' })} data-aos={dataAos}>
          {title}
          {cardType === CardType.Type4 && icon && (
            <div className={s.card_swiper_bg}>
              <Image src={icon} layout="fill" alt="" />
            </div>
          )}
        </div>
      )}
      {content && (
        <div data-aos={dataAos} className={cn(s.card_swiper_content, 'text3', { [s.card_swiper_content_tip]: !title })}>
          {content}
        </div>
      )}
      {icon && cardType !== CardType.Type4 && (
        <div className={s.card_swiper_bg} data-aos={dataAos}>
          <Image src={icon} layout="fill" alt="" />
        </div>
      )}
    </div>
  )
})

const HomeOurClient: React.FC<Props> = (props) => {
  const router = useRouter()
  const { list, cardType, renderChild, wrapperClass, cardClass, typeBg, cardItemClass, aos = true, swiperIndex = 0, onSlideChange } = props
  const swiperRef = useRef<any>(null)

  useEffect(() => {
    swiperRef.current?.slideTo(swiperIndex)
  }, [swiperIndex])

  return (
    <Swiper
      className={cn(s.swiper_card_list, wrapperClass)}
      speed={400}
      slidesPerView={'auto'}
      spaceBetween={0}
      centeredSlides={false}
      onSwiper={(swiper) => {
        swiperRef.current = swiper
      }}
      onSlideChange={(swiper) => {
        onSlideChange?.(swiper.activeIndex)
      }}
    >
      {list.map((item, index) => (
        <SwiperSlide key={item.id || index} virtualIndex={index} className={cn(s.swiper_card_item, cardItemClass, { [s.swiper_card_item_ar]: router.locale === 'ar' })}>
          {cardType !== CardType.Custom ? (
            <GenericCard {...item} cardType={cardType} typeBg={typeBg} wrapperClass={cardClass} aos={aos} />
          ) : (
            renderChild?.(item, index)
          )}
        </SwiperSlide>
      ))}
    </Swiper>
  )
}
export default React.memo(HomeOurClient)